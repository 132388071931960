const supportingStartups = [
    {
        id: "00",
        name: "funq",
        website: "https://wallet.funq.club/",
        img: "https://i.ibb.co/cvr45fK/funq.png",
        about: "Funq is a web3 platform that helps teenagers learn and earn through gamification.",
        description: "",
    },
    {
        id: "01",
        name: "cinetokens",
        website: "https://www.cinetokens.com/",
        img: "https://i.ibb.co/99V6LNK/IMG-20220517-131114-594-01-2.jpg",
        about: "Powering Value Maximization in Cinema with Blockchain",
        description:
            "CineTokens is a blockchain project that attempts to enhance the value creation opportunities in cinema, linking to the real world by leveraging the concept of decentralization. This reaches out to every stakeholder in the cinema ecosystem and ensures larger participation by creating a new set of stakeholders in the industry.",
    },
    {
        id: "02",
        name: "renai",
        website: "https://renai.io/",
        img: "https://i.ibb.co/qk3mjgw/huge-1.png",
        about: "Marketplace to make food trade easy, fast and transparent",
        description:
            "Renai connects farmers with backers to fund their farming initiative. In return, backers get ownership of their future produce.",
    },
];

export default supportingStartups;
