import { Grid } from "@material-ui/core";
import "./style.css";


const UpcomingNFTs = () => {
  return (
    <div className="steps">
      <div className="text-center mb-100">
        <h3 className="mb-5">
          <strong>SOON TO BE LAUNCHED</strong>
        </h3>
        <br />
        <div className="container">
          <Grid container className="justify-content-center text-center">
            <Grid item lg={3} sm={3} xs={12} md={3} xl={3} className="">
              <div className="card ccard grid-shadow">
                <div
                  className="bg-image hover-overlay ripple"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src="https://i.ibb.co/8mfmvP0/Valentines-Day-Other-Pgs.png"
                    className="img-fluid"
                    alt="Member NFTs"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    ></div>
                  </a>
                </div>
                <div>
                  <h4 className="card-title">Member NFTs</h4>
                </div>
              </div>
            </Grid>

            <Grid item lg={3} sm={3} xs={12} md={3} xl={3} className="">
              <div className="card ccard grid-shadow">
                <div
                  className="bg-image hover-overlay ripple"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src="https://i.ibb.co/jhKv5mK/image.png"
                    className="img-fluid"
                    alt=""
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    ></div>
                  </a>
                </div>
                <div>
                  <h4>Avatar List</h4>
                </div>
              </div>
            </Grid>

            <Grid item lg={3} sm={3} xs={12} md={3} xl={3} className="">
              <div className="card ccard grid-shadow">
                <div
                  className="bg-image hover-overlay ripple"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src="https://i.ibb.co/5hXP2DK/Office-SQ.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    ></div>
                  </a>
                </div>
                <div>
                  <h4 className="card-title">Gift NFTs</h4>
                </div>
              </div>
            </Grid>
            <Grid item lg={3} sm={3} xs={12} md={3} xl={3} className="">
              <div className="card ccard grid-shadow">
                <div
                  className="bg-image hover-overlay ripple"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src="https://i.ibb.co/L1yjHfK/image.png"
                    className="img-fluid"
                    alt=""
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    ></div>
                  </a>
                </div>
                <div>
                  <h4 className="card-title">Panda Collection</h4>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UpcomingNFTs;
