export const FILE_UPLOAD = `mutation ($file: String!, $fileType: String) {
    upload_file(file: $file, file_type: $fileType) {
      message
      url
    }
  }`;

  export const SAVE_BETA_USERS = `mutation ($name: String, $email: String!, $message: String) {
    save_beta_users(name: $name, email: $email, message: $message){
      message
    }
  }`;