import React from "react";
import "./banner.css";

function Banner() {
    return (
        <>
            <section
                className="container-fluid banner-container"
                style={{ backgroundColor: "rgb(26,27,40)", color: "white" }}
            >
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-md-6 banner-txt">
                            <h1>
                                {/* TokenList is a low code platform for entrepreneurs to launch their business on web3 and go
                                global */}
                                TokenList helps businesses to launch their brands on web3 without coding
                            </h1>
                            <p style={{ fontSize: 20 }}>
                                Helping brands to accept crypto, carryout payouts, tokenise assets, launch branded tokens
                                and marketplace.
                                {/* Real-world assets can now be tokenised through smart contract. Our platform can be used to
                                tokenise securities, properties, hospitality, travel, entertainment, jewellery, membership,
                                loyalty points & coupons, merchandise etc. */}
                            </p>
                            {/* <p>
                                invest in privately held companies from the comfort of your
                                mobile phone in just a couple of clicks
                            </p> */}
                            <div className="btn-row row">
                                <a href="/schedule" className="btn">
                                    <button
                                        className="btn btn-outline-primary clr"
                                        style={{ borderColor: "#3dc3ff", color: "#3dc3ff" }}
                                    >
                                        Book a demo
                                    </button>
                                </a>

                                <a href="https://wallet.thetokenlist.com/auth/signin" className="btn">
                                    <button
                                        className="btn btn-primary clr"
                                        style={{
                                            backgroundColor: "#3dc3ff",
                                            color: "black",
                                            border: 0,
                                        }}
                                    >
                                        Get early access
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 banner-img">
                            <img src="assets/images/undraw_transfer_money_re_6o1h.svg" alt="" />
                        </div>
                        <br />
                        &nbsp;
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner;
