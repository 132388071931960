export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const SET_STATE_UNDIFINED = "SET_STATE_UNDIFINED";

export const UPDATE_PHANTOM_CONNECT = "UPDATE_PHANTOM_CONNECT";
export const UPDATE_PHANTOM_INSTALL = "UPDATE_PHANTOM_INSTALL";
export const UPDATE_PUBLIC_KEY = "UPDATE_PUBLIC_KEY";
export const RESTORE_PHANTOM_DATA = "RESTORE_PHANTOM_DATA";
export const UPDATE_LOADING_TEXT = "UPDATE_LOADING_TEXT";
export const RESET_LOADING_TEXT = "RESET_LOADING_TEXT";

export const LIST_ALL_CREATORS = "LIST_ALL_CREATORS";
export const LIST_ALL_CREATOR_NFTS = "LIST_ALL_CREATOR_NFTS";

export const RESET_ALL_CREATORS = "RESET_ALL_CREATORS";
export const RESET_ALL_CREATORS_NFTS = "RESET_ALL_CREATORS_NFTS";

export const LIST_NFT_DETAILS = "LIST_NFT_DETAILS";

export const LIST_ALL_SELLING_NFTS = " LIST_ALL_SELLING_NFTS";
export const RESET_SELLING_NFTS = "RESET_SELLING_NFTS";

export const LIST_ALL_USER_NFTS = "LIST_ALL_USER_NFTS";
export const RESET_USER_NFTS = "RESET_USER_NFTS";

export const UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE";
