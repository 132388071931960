import { LAMPORTS_PER_SOL } from "@solana/web3.js";

export const convertToSOL = (amount, fromInr = false) => {
    if (fromInr) {
        amount = amount * (0.01).toFixed(2);
        amount = amount * LAMPORTS_PER_SOL;
    }
    return amount / LAMPORTS_PER_SOL;
};

export const convertToLamports = (amount, fromInr = false) => {
    if (fromInr) {
        amount = amount * (0.01).toFixed(2);
    }
    return amount * LAMPORTS_PER_SOL;
};
