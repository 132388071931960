import React from "react";

import { Grid, List, ListItem, ListItemText } from "@material-ui/core";

import CustomTextButton from "components/CustomButton/CustomTextButton";

const steps = [
    "Step 1 : Login to godaddy",
    "Step 2 : Goto products",
    "Step 3 : Select the domain",
    "Step 4 : Click on manage domain",
    "Step 5 : Select Manage DNS",
    "Step 6: Update the IP address 15.206.245.91",
    "Step 7 : In order to create subdomains, click the add button, type the subdomain's name 15.206.245.91",
];

export default ({handleSubmit}) => {
    return (
        <>
            <Grid container justify="center">
                <Grid item>
                    <h3 style={{ color: "white" }}>How to configure your domain</h3>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <List>
                        {steps.map((e, i) => {
                            return (
                                <ListItem key={i}>
                                    <ListItemText primary={e} primaryTypographyProps={{ color: "secondary" }} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <CustomTextButton title="Continue" onClick={()=>handleSubmit(2)} />
            </Grid>
        </>
    );
};
