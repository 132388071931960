import { Grid } from "@material-ui/core";
import React from "react";
import Footer from "views/shared/footer";
import Navbar from "views/shared/navbar";
import "../landing-page/style.css";

const ContactUs = () => {
    return (
        <div>
            <Navbar />
            <section
                id="features"
                className="features container-fluid"
                style={{ backgroundColor: "rgb(38,39,52)", height: "100vh" }}
            >
                <div className="container" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="row section-title">
                        <h2 style={{ color: "white" }}>Contact Us</h2>
                    </div>
                </div>
                <Grid container justify="center" spacing={2}>
                    <Grid item>
                        <p>
                            If you would like to: access, correct, amend or delete any personal information we have about
                            you, register a complaint, or simply want more information contact our Privacy Compliance
                            Officer at <b>renai.pay.ai@gmail.com</b> or by mail at:-
                        </p>
                        <p>To,</p>
                        <p>The Director,</p>
                        <p>Metabasu Technologies Private Limited</p>
                        <p>13/403A, Navapuram Parayil, Ezhupunna PO, Kerala 688537</p>
                    </Grid>
                </Grid>
            </section>
            <Footer />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"
            />
        </div>
    );
};

export default ContactUs;
