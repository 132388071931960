import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Tab, Tabs } from "@material-ui/core";

import componentStyles from "assets/theme/views/auth/login.js";
import NftUserContent from "./dataContent";
import { listAllUserNfts, resetUserNfts } from "store/actions";
import CustomTextButton from "components/CustomButton/CustomTextButton";

const useStyles = makeStyles(componentStyles);

const UserNftList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dataType, setDataType] = useState("all");
    const [tabValue, setTabValue] = useState(0);

    const { nftData, viewMoreButton } = useSelector(
        ({
            nfts: {
                userNfts: { list, showViewMore },
            },
        }) => ({
            nftData: list,
            viewMoreButton: showViewMore,
        })
    );

    const fetchNftData = () => {
        dispatch(listAllUserNfts({ type: dataType }));
    };

    useEffect(() => {
        dispatch(resetUserNfts());
        fetchNftData();
    }, [dataType]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Grid container justify="flex-start" spacing={2} style={{ padding: "1em" }}>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="All" onClick={() => setDataType("all")} style={{ color: "white", fontSize: 15 }} />
                            <Tab label="Own" onClick={() => setDataType("own")} style={{ color: "white", fontSize: 15 }} />
                            <Tab
                                label="Created"
                                onClick={() => setDataType("created")}
                                style={{ color: "white", fontSize: 15 }}
                            />
                        </Tabs>
                    </Box>
                </Box>
            </Grid>
            <Grid container justify="flex-start" spacing={3} style={{ padding: "1em" }}>
                <NftUserContent data={nftData} />
            </Grid>
            <Grid container justify="center" spacing={3} style={{ padding: "1em" }}>
                {viewMoreButton ? (
                    <Grid item>
                        <CustomTextButton title={"Show More"} onClick={() => fetchNftData()} />
                    </Grid>
                ) : null}
            </Grid>
        </>
    );
};

export default UserNftList;
