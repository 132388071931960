import React from 'react';
import { Grid } from "@material-ui/core";
import supportingStartups from "data/startups";
import { history } from "utils";

function Startups({ setIndex }) {

    const handleExploreButton = (i) => {
        setIndex(i);
        history.push("/explore");
    };

    return (
        <>
            <section className="testimonial-container">
                <div className="container">
                    <div className="title">
                        <h2>startups we are supporting</h2>
                    </div>

                    <Grid container justify="center">
                        {supportingStartups.map((e, i) => (
                            <Grid item lg={3} md={7} sm={7} xs={10} key={i}>
                                <div className="testimonial">
                                    <div className="pic">
                                        <img src={e.img} alt="" />
                                    </div>
                                    <div className="description">
                                        <h5>{e.name}</h5>
                                        <p>{e.about}</p>
                                    </div>
                                    <h3 className="title" onClick={() => handleExploreButton(i)}>
                                        Explore <i className="fas fa-arrow-right" />
                                    </h3>
                                </div>
                            </Grid>
                        ))}

                    </Grid>
                    {/* <div style={{ textAlign: "center" }}>
                    <a href="/listings" style={{ color: "#3dc3ff" }}>
                        <br />
                        <br />
                        View more <i className="fas fa-arrow-right" />
                    </a>
                </div> */}
                    <Grid container justify="center">
                        <Grid item lg={11} md={11} sm={11} xs={11}>
                            <div
                                style={{ backgroundColor: "#1A1B28", padding: "30px", textAlign: "center" }}
                            >
                                <h3 style={{ fontFamily: "'Maven Pro', sans-serif" }}>
                                    get in touch with us
                                </h3>
                                <a href="/schedule" className="btn">
                                    <button
                                        className="btn btn-primary clr"
                                        style={{
                                            backgroundColor: "#3dc3ff",
                                            color: "black",
                                            border: 0,
                                        }}
                                    >
                                        Click here
                                    </button>
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    )
}

export default Startups