import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    // Stack,
    TextField,
    Button,
    CircularProgress,
    Slide,
} from "@material-ui/core";
import { Email, Person, Phone, Public } from "@material-ui/icons";
import { countryISDCodes } from "data/isdCodeList";
import { Formik } from "formik";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { toast } from "react-toastify";
import Navbar from "views/shared/navbar";
import * as Yup from "yup";

const errMsgStyle = {
    display: "block",
    color: "red",
    fontSize: 13,
}

export default function ScheduleDemoPage() {
    const [isd, setIsd] = useState("91");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = "rgb(26,27,40)";
    }, [])

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        website: "",
        note: "",
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required("Name is required"),
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
        phone: Yup.string().max(255).required("Phone number is required"),
    })

    const handleSubmit = async (values) => {
        setIsLoading(true);
        values.isdCode = `+${isd}`;

        const SPREADSHEET_ID = process.env.REACT_APP_GL_SPREADSHEET_ID;
        const SHEET_ID = process.env.REACT_APP_GL_SHEET_ID.toString();
        const CLIENT_EMAIL = process.env.REACT_APP_GL_CLIENT_EMAIL;
        const PRIVATE_KEY = process.env.REACT_APP_GL_PRIVATE_KEY;

        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            // loads document properties and worksheets
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            const rows = await sheet.getRows();

            const filtered = rows.filter((e) => e.Email === values.email);

            if (!filtered.length) {
                let date = new Date();
                await sheet.addRow({
                    Date: date.toLocaleString(),
                    Email: values.email,
                    Name: values.name,
                    ISD: values.isdCode,
                    Phone: values.phone,
                    Website: values.website,
                    Note: values.note,
                });
            }

            setIsLoading(false);
            return toast.success("Schedule successful");
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
            return toast.error("Failed to schedule");
        }
    }

    const handleIsdChange = (e) => {
        setIsd(e.target.value);
    };

    return (
        <>
            <Navbar />
            <Box sx={{ width: "100%", color: "#fff" }}>
                <Container>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifycontent="center"
                        sx={{ minHeight: "100vh", p: "40px 10px", pt: "80px" }}
                    >
                        <Grid
                            container
                            spacing={8}
                            alignItems="center"
                            justifycontent="center"
                        >
                            <Grid item lg={6} md={6} sm={8} xs={12}>
                                <Slide direction="down" in={true} mountOnEnter unmountOnExit>
                                    <Box spacing={3}>
                                        <h1 style={{ color: "#3dc3ff", fontSize:"25px" }}>
                                            <b>Reach out to us</b>
                                        </h1>
                                        <br/>
                                        <h5 style={{ color: "#ccc", paddingRight: "4rem" }}>
                                            Get a demo where we will help you understand how you can wow your team.
                                        </h5>
                                    </Box>
                                </Slide>
                            </Grid>
                            <Grid item lg={6} md={6} sm={8} xs={12}>
                                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                                    <Card>
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSubmit}
                                        >
                                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <Box sx={{ p: 3 }}>
                                                        <Box>
                                                            <OutlinedInput
                                                                fullWidth
                                                                size="small"
                                                                autoComplete="on"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Your name"
                                                                defaultValue={values.name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                required={true}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <Person />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            {Boolean(touched.name && errors.name) ? (
                                                                <div style={errMsgStyle}>
                                                                    {errors.name}
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Box>
                                                        <br />
                                                        <Box>
                                                            <OutlinedInput
                                                                fullWidth
                                                                size="small"
                                                                autoComplete="on"
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email"
                                                                defaultValue={values.email}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                required={true}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <Email />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            {Boolean(touched.email && errors.email) ? (
                                                                <div style={errMsgStyle}>
                                                                    {errors.email}
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Box>
                                                        <br />
                                                        <Box display={"flex"}>
                                                            <Box sx={{ width: "30%" }}>
                                                                <FormControl sx={{ width: "30%" }}>
                                                                    <Select value={isd} onChange={handleIsdChange}>
                                                                        {countryISDCodes.map((countries, index) => (
                                                                            <MenuItem value={countries?.code} key={index}>
                                                                                {countries?.iso}- {countries?.country}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <Box sx={{ width: "70%" }}>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    size="small"
                                                                    autoComplete="on"
                                                                    type="number"
                                                                    name="phone"
                                                                    placeholder="Phone number"
                                                                    defaultValue={values.phone}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    required={true}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <Phone /> +{isd}
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                                {Boolean(touched.phone && errors.phone) ? (
                                                                    <div style={errMsgStyle}>
                                                                        {errors.phone}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <br />
                                                        <Box>
                                                            <OutlinedInput
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="website"
                                                                placeholder="Your website"
                                                                defaultValue={values.website}
                                                                onChange={handleChange}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <Public />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </Box>
                                                        <br />
                                                        <Box>
                                                            <TextField
                                                                fullWidth
                                                                type="text"
                                                                name="note"
                                                                placeholder="What is your need?"
                                                                defaultValue={values.note}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <br />
                                                        <Button
                                                            fullWidth
                                                            type="submit"
                                                            variant="contained"
                                                        >
                                                            {isLoading ?
                                                                <CircularProgress size={23} color="inherit" /> :
                                                                <b>Schedule meeting</b>
                                                            }
                                                        </Button>
                                                    </Box>
                                                </form>
                                            )}
                                        </Formik>
                                    </Card>
                                </Slide>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
}
