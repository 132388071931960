import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { FilledInput, Box, CircularProgress, Backdrop } from "@material-ui/core";

import { sellAnNft } from "store/actions";
import { convertToLamports } from "helpers";
import CustomTextButton from "components/CustomButton/CustomTextButton";

const SellingModalContent = ({ nftId, dispatch }) => {
    const { loading } = useSelector(({ loading }) => ({
        loading: loading,
    }));

    const handleSellNow = ({ amount }) => {
        dispatch(
            sellAnNft({
                nftId: nftId,
                amount: convertToLamports(amount),
            })
        );
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Formik
                initialValues={{ amount: "" }}
                validationSchema={Yup.object().shape({
                    amount: Yup.number()
                        .moreThan(0)
                        // .min(500, "Must be greater than 500")
                        .required("Selling Price is required"),
                })}
                onSubmit={(values) => {
                    handleSellNow({ amount: values.amount });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name" style={{ textTransform: "uppercase", color: "white", fontSize: 15 }}>
                            Selling Price (SOL)
                        </label>
                        <br />
                        <FilledInput
                            key="amount"
                            name="amount"
                            autoComplete="off"
                            type="number"
                            onBlur={handleBlur}
                            placeholder="Selling Price"
                            onChange={handleChange}
                            value={values.amount}
                            error={Boolean(touched.amount && errors.amount)}
                            inputProps={{ min: 0 }}
                        />
                        {Boolean(touched.amount && errors.amount) ? (
                            <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                {errors.amount}
                            </div>
                        ) : (
                            ""
                        )}
                        <Box my={2}>
                            <CustomTextButton
                                disabled={values.amount === 0 || values.amount === ""}
                                onClick={handleSubmit}
                                size="large"
                                type="submit"
                                variant="contained"
                                title="Confirm"
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default SellingModalContent;
