import React from "react";
import "./benefits.css";
import img1 from "assets/img/benefits/img1.png";
import img2 from "assets/img/benefits/img2.png";
import img3 from "assets/img/benefits/img3.png";
import img4 from "assets/img/benefits/img4.png";

function Benefits() {

    const benefits = [
        { title: "shorter development cycle", img: img1 },
        { title: "rapid prototyping", img: img2 },
        { title: "lower barrier to entry", img: img3 },
        { title: "improved productivity", img: img4 },
    ]

    return (
        <>
            <section id="features" className="features container-fluid">
                <div className="container">

                    <div>
                        <h3>
                            <span>benefits of The TokenList</span> low code web
                            3 platform{" "}
                        </h3>
                    </div>

                    <div className="row mt-5 feature-row">
                        {benefits.map((e, i) => (
                            <div className="col-md-3" key={i}>
                                <div className="feature-col">
                                    <img src={e.img} alt="" />
                                    <h4>{e.title}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default Benefits;
