import { OPEN_MODAL, CLOSE_MODAL } from "../types";

export const openModal = (data) => {
    return async (dispatch) => {
        dispatch({ type: OPEN_MODAL, payload: data });
    };
};

export const closeModal = () => {
    return async (dispatch) => {
        dispatch({ type: CLOSE_MODAL });
    };
};
