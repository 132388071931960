import { toast } from "react-toastify";
import { unAuthorizedPost } from "services";
import { FETCH_ALL_CREATORS, FETCH_ALL_CREATOR_NFTS, SAVE_BETA_USERS } from "graphql";
import {
    TOGGLE_LOADING,
    LIST_ALL_CREATORS,
    LIST_ALL_CREATOR_NFTS,
    RESET_ALL_CREATORS,
    RESET_ALL_CREATORS_NFTS,
} from "../types";


export const listAllCreators = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().creators;

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_ALL_CREATORS,
            variables: {
                pageNumber: pageNumber,
                pageSize: pageSize,
            },
        });

        if (apiResponse.data?.customStatus) {
            let {
                list_all_creators: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_CREATORS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllCreatorNfts = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            arts: { pageSize, pageNumber, list },
        } = getState().creators;

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_ALL_CREATOR_NFTS,
            variables: {
                pageNumber: pageNumber,
                pageSize: pageSize,
                ...requestData,
            },
        });

        if (apiResponse.data?.customStatus) {
            let {
                list_all_creator_nfts: {
                    data: { creator, arts },
                    hasMore,
                },
            } = apiResponse.data.data;

            let updatedList = [...list, ...arts];

            dispatch({
                type: LIST_ALL_CREATOR_NFTS,
                payload: {
                    details: creator,
                    arts: {
                        list: updatedList,
                        showViewMore: hasMore,
                        pageNumber: pageNumber + 1,
                    },
                },
            });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllCreators = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_CREATORS });
    };
};

export const resetAllCreatorNfts = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_CREATORS_NFTS });
    };
};

export const saveBetaUsers = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });


        let apiResponse = await unAuthorizedPost("", {
            query: SAVE_BETA_USERS,
            variables: requestData,
        });

        if (apiResponse.data?.customStatus) {

            toast.success("Inputs sent");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};