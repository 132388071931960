import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FilledInput, Backdrop, CircularProgress } from "@material-ui/core";
import CustomTextButton from "components/CustomButton/CustomTextButton";

const LoginWithOtp = ({ onSubmit, loading }) => {
    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Formik
                initialValues={{
                    otp: "",
                }}
                validationSchema={Yup.object().shape({
                    otp: Yup.string().max(255).required("OTP is required"),
                })}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" style={{ textTransform: "uppercase", color: "white",fontSize:15 }}>
                                OTP
                            </label>
                            <br />
                            <FilledInput
                                key="otp"
                                name="otp"
                                autoComplete="off"
                                type="text"
                                onBlur={handleBlur}
                                placeholder="OTP"
                                onChange={handleChange}
                                value={values.otp}
                                error={Boolean(touched.otp && errors.otp)}
                            />
                            {Boolean(touched.otp && errors.otp) ? (
                                <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                    {errors.otp}
                                </div>
                            ) : (
                                ""
                            )}
                            <br />
                        </div>
                        <div>
                            <CustomTextButton
                                title="Sign In"
                                style={{ width: "100%" }}
                                onClick={handleSubmit}
                                disabled={values.otp === ""}
                                type="submit"
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default LoginWithOtp;
