import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons components
import MenuIcon from "@material-ui/icons/Menu";
import { Person, Dashboard, Clear, Explore, AccountBalanceWallet, Home } from "@material-ui/icons";

// core components
import componentStyles from "assets/theme/components/auth-navbar.js";
import CustomModal from "components/CustomModal/CustomModal";
import ProfileModalContent from "./ProfileModal";
import { isLoggedIn, history } from "utils";
import LoginModalContent from "./LoginModal";
import { loginSuccess, logoutUser, openModal } from "store/actions";

const useStyles = makeStyles(componentStyles);

export default function AuthNavbar() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(1);

    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const { isAuthenticated, userData } = useSelector(({ auth: { token, user } }) => ({
        isAuthenticated: isLoggedIn(token),
        userData: user,
    }));

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(loginSuccess());
        } else {
            dispatch(logoutUser());
        }
    }, [isAuthenticated]);

    const menuId = "responsive-menu-id";
    const ListObject = (
        <Box display="flex" alignItems="center" width="auto" component={List} className={classes.flexDirectionColumn}>
            <ListItem
                onClick={() => {
                    setSelectedIndex(1);
                    window.open("/", "_self");
                }}
                selected={selectedIndex === 1}
                classes={{
                    root: classes.listItemRoot,
                }}
            >
                <Box component={Home} width="1.25rem!important" height="1.25rem!important" marginRight=".5rem!important" />
                Home
            </ListItem>
            <ListItem
                onClick={() => {
                    setSelectedIndex(1);
                    history.push("/auth/listings");
                }}
                selected={selectedIndex === 1}
                classes={{
                    root: classes.listItemRoot,
                }}
            >
                <Box
                    component={Explore}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    marginRight=".5rem!important"
                />
                Listings
            </ListItem>
            {/* <ListItem
                onClick={() => {
                    setSelectedIndex(2);
                    history.push("/auth/creators");
                }}
                selected={selectedIndex === 2}
                classes={{
                    root: classes.listItemRoot,
                }}
            >
                <Box
                    component={Person}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    marginRight=".5rem!important"
                />
                Creators
            </ListItem> */}
            {/* {isAuthenticated ? (
                <ListItem
                    onClick={() => dispatch(openModal(<ProfileModalContent />))}
                    classes={{
                        root: classes.listItemRoot,
                    }}
                    style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}
                >
                    <Box
                        component={AccountBalanceWallet}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        marginRight=".5rem!important"
                    />
                    {userData?.name ?? "User"}
                </ListItem>
            ) : (
                <ListItem
                    onClick={() => dispatch(openModal(<LoginModalContent />))}
                    classes={{
                        root: classes.listItemRoot,
                    }}
                >
                    <Box
                        component={Dashboard}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        marginRight=".5rem!important"
                    />
                    SignIn
                </ListItem>
            )} */}
        </Box>
    );
    return (
        <>
            <AppBar position="absolute" color="transparent" elevation={0}>
                <Toolbar>
                    <Container
                        display="flex!important"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop=".75rem"
                        component={Box}
                        maxWidth="xl"
                    >
                        <Hidden mdUp implementation="css">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleMenuOpen}
                                aria-controls={menuId}
                                aria-haspopup="true"
                            >
                                <Box
                                    component={MenuIcon}
                                    color={theme.palette.white.main}
                                    width="2rem!important"
                                    height="2rem!important"
                                />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                id={menuId}
                                keepMounted
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                classes={{ paper: classes.menuPaper }}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    paddingLeft="1.25rem"
                                    paddingRight="1.25rem"
                                    paddingBottom="1rem"
                                    className={classes.outlineNone}
                                >
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleMenuClose}
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                    >
                                        <Box component={Clear} width="2rem!important" height="2rem!important" />
                                    </IconButton>
                                </Box>
                                <Box
                                    component={Divider}
                                    marginBottom="1rem!important"
                                    marginLeft="1.25rem!important"
                                    marginRight="1.25rem!important"
                                />
                                {ListObject}
                            </Menu>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            {ListObject}
                        </Hidden>
                        <CustomModal title={isAuthenticated ? "" : "Sign In"} />
                    </Container>
                </Toolbar>
            </AppBar>
        </>
    );
}
