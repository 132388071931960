import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { create } from "apisauce";
import { toast } from "react-toastify";

import { Grid } from "@material-ui/core";
import { Input, FormGroup } from "reactstrap";

import Loader from "components/Loader";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import GodaddySteps from "./godaddySteps";

import { DEPLOY_NEW_APPLICATION } from "graphql";
import { getBase64 } from "utils";

const styles = {
    card: {
        padding: "50px 40px 20px",
        margin: "0px 0px 50px",
        borderRadius: "8px",
        backgroundColor: "#1A1B28",
        // position: "relative",
    },
    errMsg: {
        display: "block",
        marginLeft: "10px",
        marginTop: "-10px",
        color: "red",
        fontSize: 13,
    },
    section: {
        width: "100%",
        backgroundColor: "rgb(38,39,52)",
        minHeight: "100vh",
    },
    input: {
        width: "100%",
        border: "0",
        padding: "14px",
        marginBottom: "20px",
        borderRadius: "5px",
        backgroundColor: "rgb(38,39,52)",
        color: "white",
    },
};

function CreateWebApp() {
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [base64Img, setBase64Img] = useState("");

    const handleSubmit = async (data) => {
        try {
            setIsLoading(true);
            const api = create({
                baseURL: "https://api-multi-domains.thetokenlist.com/graphql/nft-middleware/creators",
                timeout: 30000,
            });

            const response = await api.post("/", { query: DEPLOY_NEW_APPLICATION, variables: data });
            if (response?.data?.errors) {
                setIsLoading(false);
                return toast.error(response?.data?.errors[0]?.message ?? "Failed to deploy the application");
            }
            setStep(0);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            return toast.error("Failed to deploy the application");
        }
    };

    const updateStep = (value) => {
        setStep(value);
    };

    const imgToBase64 = async (file) => {
        let data = await getBase64(file);
        setBase64Img(data);
    };

    return (
        <div>
            <Loader visible={isLoading} />
            {/*  ************************* Page Title Starts Here ************************** */}
            <div className="page-nav no-margin row" style={{ backgroundColor: "rgb(38,39,52)" }}>
                <Grid container justify="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h2 style={{ color: "white", fontFamily: "El Messiri" }}>Deploy new web app</h2>
                    </Grid>
                    <Grid item>
                        <ul>
                            <li>
                                {" "}
                                <a href="/" style={{ color: "white" }}>
                                    <i className="fas fa-home" style={{ color: "white" }} /> home
                                </a>
                            </li>
                            <li style={{ color: "white" }}>
                                <i className="fas fa-angle-double-right" style={{ color: "white" }} /> webapp
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
            {/*  ************************* Contact Us Starts Here ************************** */}

            <section style={styles.section}>
                <Grid container justify="center">
                    <Grid item lg={7} md={8} sm={8} xs={10}>
                        <div style={styles.card}>
                            {step === 1 ? (
                                <>
                                    <GodaddySteps handleSubmit={updateStep} />
                                </>
                            ) : step === 2 ? (
                                <Formik
                                    initialValues={{
                                        appName: "",
                                        domainName: "",
                                        // ipAddress: "",
                                        header: "",
                                        description: "",
                                        mobile: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                        appName: Yup.string().max(255).required("App name is required"),
                                        domainName: Yup.string().max(255).required("Domain name is required"),
                                        mobile: Yup.string().min(10).max(10).required("Contact Number is required"),
                                        // ipAddress: Yup.string().max(255).required("IP address is required"),
                                    })}
                                    onSubmit={(value) => {
                                        value.ipAddress = "15.206.245.91";
                                        value.mobile = value.mobile.toString();
                                        handleSubmit(value);
                                        // setIsLoading(true);
                                        // setTimeout(() => {
                                        //     setIsLoading(false);
                                        //     setStep(3);
                                        // }, 5000);
                                    }}
                                >
                                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                        <form
                                            onSubmit={() => {
                                                handleSubmit(values);
                                            }}
                                        >
                                            <div>
                                                <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                                    App Name
                                                </label>
                                                <input
                                                    key="appName"
                                                    name="appName"
                                                    autoComplete="off"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Enter Application Name"
                                                    onChange={handleChange}
                                                    value={values.appName}
                                                    style={styles.input}
                                                    // error={Boolean(touched.appName && errors.appName)}
                                                />
                                                {Boolean(touched.appName && errors.appName) ? (
                                                    <div style={styles.errMsg}>{errors.appName}</div>
                                                ) : (
                                                    ""
                                                )}
                                                <br />

                                                <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                                    Domain Name
                                                </label>
                                                <input
                                                    key="domainName"
                                                    name="domainName"
                                                    autoComplete="off"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Enter Domain Name"
                                                    onChange={handleChange}
                                                    value={values.domainName}
                                                    style={styles.input}
                                                />
                                                {Boolean(touched.domainName && errors.domainName) ? (
                                                    <div style={styles.errMsg}>{errors.domainName}</div>
                                                ) : (
                                                    ""
                                                )}
                                                <br />

                                                <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                                    App Heading
                                                </label>
                                                <input
                                                    key="header"
                                                    name="header"
                                                    autoComplete="off"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.header}
                                                    style={styles.input}
                                                    // error={Boolean(touched.header && errors.header)}
                                                />
                                                {Boolean(touched.header && errors.header) ? (
                                                    <div style={styles.errMsg}>{errors.header}</div>
                                                ) : (
                                                    ""
                                                )}
                                                <br />

                                                <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                                    App description
                                                </label>
                                                <input
                                                    key="description"
                                                    name="description"
                                                    autoComplete="off"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.description}
                                                    style={styles.input}
                                                    // error={Boolean(touched.description && errors.description)}
                                                />
                                                {Boolean(touched.description && errors.description) ? (
                                                    <div style={styles.errMsg}>{errors.description}</div>
                                                ) : (
                                                    ""
                                                )}
                                                <br />

                                                <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                                    Contact Number
                                                </label>
                                                <input
                                                    key="mobile"
                                                    name="mobile"
                                                    autoComplete="off"
                                                    type="number"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.mobile}
                                                    style={styles.input}
                                                    // error={Boolean(touched.mobile && errors.mobile)}
                                                />
                                                {Boolean(touched.mobile && errors.mobile) ? (
                                                    <div style={styles.errMsg}>{errors.mobile}</div>
                                                ) : (
                                                    ""
                                                )}
                                                <br />

                                                <FormGroup key="artwork">
                                                    <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                                        App Icon
                                                    </label>
                                                    <Input
                                                        type="file"
                                                        name="artwork"
                                                        onChange={(e) => {
                                                            imgToBase64(e.target.files);
                                                        }}
                                                        onBlur={handleBlur}
                                                        accept="image/*"
                                                    />
                                                </FormGroup>
                                                <br />
                                            </div>

                                            <div>
                                                <CustomTextButton
                                                    title="Submit"
                                                    style={{ width: "100%" }}
                                                    onClick={handleSubmit}
                                                    disabled={values.appName === "" || values.domainName === ""}
                                                    type="submit"
                                                />
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h3 style={{ color: "white" }}>In 30 minutes, your application will be deployed</h3>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </section>
        </div>
    );
}

export default CreateWebApp;
