import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { CircularProgress, Backdrop, FilledInput, Grid } from "@material-ui/core";
import { Connection, PublicKey, Transaction, clusterApiUrl, SystemProgram } from "@solana/web3.js";

import CustomTextButton from "components/CustomButton/CustomTextButton";
import { toggleLoading, closeModal, purchaseNft } from "store/actions";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { graphqlBaseUrl, RAZORPAY_KEY_ID, SOLANA_CLUSTER, convertToLamports, convertToSOL } from "helpers";
import { history, currencyFormat } from "utils";
import { purchaseMultipleNfts } from "store/actions";

const BuyNowModalContent = ({ nftId, nftDetails, isBulk = false }) => {
    const dispatch = useDispatch();
    const [active, setActive] = useState(1);

    const { loading } = useSelector(({ loading }) => ({
        loading: loading,
    }));

    const validateSolAddress = (address) => {
        try {
            let pubkey = new PublicKey(address);
            let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
            return isSolana;
        } catch (error) {
            return false;
        }
    };

    const handleSubmit = async (values) => {
        try {
            dispatch(toggleLoading(true));
            if (values.destWalletId && values.destWalletId !== "") {
                let isSolAddress = validateSolAddress(values.destWalletId);
                if (!isSolAddress) {
                    dispatch(toggleLoading(false));
                    return toast.error("Please provide a valid solana wallet address");
                }
            }
            dispatch(closeModal());

            const result = await axios.post(`${graphqlBaseUrl}/razorpay/orders`, {
                amount: nftDetails.selling_price * 100,
            });

            const { amount, id: order_id, currency } = result.data;

            const options = {
                key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                image: nftDetails?.art_url,
                name: nftDetails?.name,
                description: "Buy NFT",
                order_id: order_id,
                handler: async function (response) {
                    try {
                        const data = {
                            orderCreationId: order_id,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                            nftId: nftId,
                            destWalletId: values.destWalletId,
                            destEmail: values.destEmail,
                        };

                        dispatch(toggleLoading(true));

                        if (!isBulk) {
                            await axios.post(`${graphqlBaseUrl}/razorpay/success`, data);
                        } else {
                            dispatch(purchaseMultipleNfts(data));
                        }
                        toast.success("Transaction Success");
                        dispatch(toggleLoading(false));
                        history.push("/auth/listings");
                    } catch (err) {
                        dispatch(toggleLoading(false));
                        return toast.error("Payment Failed. Try again after sometime");
                    }
                },
                prefill: {
                    name: "",
                    email: "",
                    contact: "",
                },
                notes: {
                    address: "",
                },
                theme: {
                    color: "#3dc3ff",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            paymentObject.on("payment.failed", function (response) {
                toast.error("Payment Failed. Try again after sometime");
            });
            dispatch(toggleLoading(false));
        } catch (err) {
            dispatch(toggleLoading(false));
            return toast.error("Failed to initaite order. Try again after sometime");
        }
    };

    const handleActive = (value) => {
        setActive(value);
        if (value === 3) {
            buyUsingSolana();
        }
    };

    const handlePhantomConnect = async () => {
        if ("solana" in window) {
            const provider = window.solana;
            if (provider.isPhantom) {
                try {
                    dispatch(toggleLoading(true));
                    const resp = await window.solana.connect();
                    // toast.success("Connected to wallet");
                    dispatch(toggleLoading(false));
                    return resp.publicKey.toString();
                } catch (err) {
                    dispatch(toggleLoading(false));
                    toast.error(err.message);
                    return "";
                }
            }
        } else {
            window.open("https://phantom.app/", "_blank");
            return "";
        }
    };

    const createTransferTransaction = async (connection, publicKey, amount) => {
        if (publicKey === "") {
            toast.info("Connect to wallet first");
            return;
        }
        if (publicKey === nftDetails?.owner_id?.wallet_id?.wallet_id) {
            toast.error("This NFT is currently held in the connected wallet. Please connect another one");
            return;
        }
        let transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: new PublicKey(publicKey),
                toPubkey: new PublicKey(nftDetails?.owner_id?.wallet_id?.wallet_id),
                lamports: amount,
            })
        );
        transaction.feePayer = new PublicKey(publicKey);

        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
        return transaction;
    };

    const buyUsingSolana = async () => {
        const NETWORK = clusterApiUrl(SOLANA_CLUSTER);
        const connection = new Connection(NETWORK);

        let publicKey = await handlePhantomConnect();
        dispatch(toggleLoading(true));

        if (publicKey) {
            let amount = convertToLamports(nftDetails?.selling_price, true);
            const transaction = await createTransferTransaction(connection, publicKey, amount);
            if (transaction) {
                try {
                    let userBalance = await connection.getBalance(new PublicKey(publicKey));
                    if (userBalance < amount) {
                        throw Error("Insufficient Balance");
                    }

                    let signed = await window.solana.signTransaction(transaction);
                    console.log("Got signature, submitting transaction");
                    let signature = await connection.sendRawTransaction(signed.serialize());
                    console.log("Submitted transaction " + signature + ", awaiting confirmation");
                    await connection.confirmTransaction(signature);
                    dispatch(purchaseNft({ destWalletId: publicKey, nftId: nftId, signature: signature }));
                } catch (err) {
                    dispatch(toggleLoading(false));
                    toast.error(err.message);
                }
            }
        }
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="secondary" />
            </Backdrop>
            {active === 1 ? (
                <Grid container spacing={1} style={{ padding: "1em" }}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <CustomTextButton
                            title={`Pay using INR (${currencyFormat(nftDetails?.selling_price)})`}
                            style={{ width: "100%" }}
                            onClick={() => handleActive(2)}
                        />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <CustomTextButton
                            title={`Pay using SOLANA (${convertToSOL(nftDetails?.selling_price, true)} SOL)`}
                            style={{ width: "100%" }}
                            onClick={() => handleActive(3)}
                        />
                    </Grid>
                </Grid>
            ) : active === 2 ? (
                <Formik
                    initialValues={{
                        destWalletId: "",
                        destEmail: "",
                    }}
                    validationSchema={Yup.object().shape({
                        destWalletId: Yup.string().max(255),
                        destEmail: Yup.string().email("invalid email address"),
                    })}
                    onSubmit={(value) => {
                        handleSubmit(value);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                        <form
                            onSubmit={() => {
                                handleSubmit(values);
                            }}
                        >
                            <div>
                                <br />
                                <label htmlFor="email" style={{ color: "white", fontSize: 15 }}>
                                    Enter destination email address
                                </label>
                                <FilledInput
                                    key="destEmail"
                                    name="destEmail"
                                    autoComplete="off"
                                    type="text"
                                    onBlur={handleBlur}
                                    placeholder="Destination email address"
                                    onChange={handleChange}
                                    value={values.destEmail}
                                    error={Boolean(touched.destEmail && errors.destEmail)}
                                    disabled={values.destWalletId !== ""}
                                />
                                {Boolean(touched.destEmail && errors.destEmail) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                        {errors.destEmail}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <br />
                            <div>
                                <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                    Enter destination wallet address
                                </label>
                                <FilledInput
                                    key="destWalletId"
                                    name="destWalletId"
                                    autoComplete="off"
                                    type="text"
                                    onBlur={handleBlur}
                                    placeholder="Destination wallet id"
                                    onChange={handleChange}
                                    value={values.destWalletId}
                                    error={Boolean(touched.destWalletId && errors.destWalletId)}
                                    disabled={values.destEmail !== ""}
                                />
                                {Boolean(touched.destWalletId && errors.destWalletId) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                        {errors.destWalletId}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <br />
                            </div>
                            <div>
                                <CustomTextButton
                                    title="Proceed to pay"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.destWalletId === "" && values.destEmail === ""}
                                    type="submit"
                                />
                            </div>
                            <br />
                            <div>
                                <strong>
                                    If you don't have a wallet address{" "}
                                    <a href="https://wallet.thetokenlist.com/auth/signup" target="_self">
                                        signup
                                    </a>{" "}
                                    to create one
                                </strong>
                            </div>
                        </form>
                    )}
                </Formik>
            ) : (
                <Grid container justify="center" style={{ padding: "1em" }}>
                    <Grid item>
                        <h5>Please wait</h5>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default BuyNowModalContent;
