import { LIST_ALL_SELLING_NFTS, LIST_NFT_DETAILS, LIST_ALL_USER_NFTS, RESET_SELLING_NFTS, RESET_USER_NFTS } from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 15,
    details: {},
    userNfts: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 15,
    },
};

const nftReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_NFT_DETAILS:
            return { ...state, details: payload };

        case LIST_ALL_SELLING_NFTS:
            return {
                ...state,
                pageSize: INITIAL_STATE.pageSize,
                ...payload,
            };

        case LIST_ALL_USER_NFTS:
            return {
                ...state,
                userNfts: {
                    pageSize: INITIAL_STATE.userNfts.pageSize,
                    ...payload,
                },
            };

        case RESET_SELLING_NFTS:
            return {
                ...state,
                list: INITIAL_STATE.list,
                pageNumber: INITIAL_STATE.pageNumber,
                pageSize: INITIAL_STATE.pageSize,
                showViewMore: INITIAL_STATE.showViewMore,
            };

        case RESET_USER_NFTS:
            return {
                ...state,
                userNfts: INITIAL_STATE.userNfts,
            };

        default:
            return state;
    }
};

export default nftReducer;
