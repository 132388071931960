import React, { useEffect } from "react";
import supportingStartups from "data/startups";
import { history } from "utils";
import { Grid } from "@material-ui/core";
//import { StartupsDetailsList } from 'datas/startups-details'

const details = {
    card: {
        padding: "140px 10px 40px",
        margin: "100px 0px 150px",
        borderRadius: "8px",
        backgroundColor: "#1A1B28",
        boxShadow: "4.871px 10px 8px 0px rgba(0, 0, 0, 0.06)",
        position: "relative",
    },
    pic: {
        backgroundColor: "white",
        borderRadius: "8px",
        width: "200px",
        height: "200px",
        border: "5px solid #3dc3ff",
        overflow: "hidden",
        margin: "0 auto",
        position: "absolute",
        top: "-100px",
        left: "0",
        right: "0",
    },
    text: {
        fontSize: "20px",
        color: "rgb(173, 173, 173)",
        lineHeight: "34px",
        position: "relative",
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    label: {
        fontSize: "15px",
        fontWeight: "900",
    },
    chip: {
        backgroundColor: "#262734",
        borderRadius: "6px",
        textAlign: "right",
        padding: "5px 20px",
        marginBottom: "10px",
        fontSize: "13px",
        fontWeight: "600",
    },
    btn: {
        display: "inline-table",
        padding: "10px 20px",
        margin: "0 auto",
        background: "#1A1B28",
        border: "1px solid #3dc3ff",
        fontSize: "10px",
        fontWeight: "700",
        color: "#3dc3ff",
        letterSpacing: "1px",
        textTransform: "uppercase",
        cursor: "pointer",
    },
};

function ExploreStartups({ index }) {
    useEffect(() => {
        if (index === null) {
            history.push("/");
        }
    }, []);

    return (
        <div>
            {/*  ************************* Page Title Starts Here ************************** */}
            <div className="page-nav no-margin " style={{ backgroundColor: "rgb(38,39,52)" }}>
                <Grid container justify="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h2 style={{ color: "white", fontFamily: "Maven Pro" }}>innovative startups</h2>
                    </Grid>
                    <Grid item>
                        <ul>
                            <li>
                                {" "}
                                <a href="/" style={{ color: "white" }}>
                                    <i className="fas fa-home" style={{ color: "white" }} /> home
                                </a>
                            </li>
                            <li style={{ color: "white" }}>
                                <i className="fas fa-angle-double-right" style={{ color: "white" }} /> explore
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
            {/*  ************************* Contact Us Starts Here ************************** */}

            <section style={{ width: "100%", backgroundColor: "rgb(38,39,52)" }}>
                <Grid container justify="center">
                    <Grid item lg={8} md={8} sm={8} xs={10}>
                        <div style={details.card}>
                            <div style={details.pic}>
                                <img
                                    src={supportingStartups[index]?.img}
                                    alt=""
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </div>
                            <div style={details.text}>
                                <div style={{ textAlign: "center" }}>
                                    <h2>{supportingStartups[index]?.name}</h2>
                                    <h6>{supportingStartups[index]?.about}</h6>
                                </div>
                                <br />

                                {supportingStartups[index]?.description ? (
                                    <Grid container>
                                        {/* <Grid item lg={4}>
                                            <label style={details.label}>Description</label>
                                        </Grid> */}
                                        <Grid item lg={12} xs={12} style={details.chip}>
                                            {supportingStartups[index]?.description ?? "-"}
                                        </Grid>
                                    </Grid>
                                ) : null}

                                {/* <Grid container>
                                    <Grid item lg={4}>
                                        <label style={details.label}>Royalties </label>
                                    </Grid>
                                    <Grid item lg={8} xs={12} style={details.chip}>
                                        {nftDetails?.royalty_percentage ?? "-"} %
                                    </Grid>
                                </Grid> */}
                                <br />
                                <Grid container justify="center">
                                    <Grid item>
                                        <h3
                                            style={details.btn}
                                            onClick={() => window.open(supportingStartups[index]?.website)}
                                        >
                                            open website <i className="fas fa-arrow-right" />
                                        </h3>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </section>
        </div>
    );
}

export default ExploreStartups;
