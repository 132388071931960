import React from "react";
import "./style.css";
import Footer from "views/shared/footer";
import Navbar from "views/shared/navbar";
import UseCases from "./useCases";
import Benefits from "./benefits";
import Banner from "./banner";
import Section4 from "./section4";
import Startups from "./startups";
import CurrentUsecases from "./currentUsecases";

const LandingLayout = ({ setIndex }) => {
    return (
        <div>
            <Navbar />
            <Banner />
            <Benefits />
            <CurrentUsecases />
            <UseCases />
            <Section4 />
            <Startups setIndex={setIndex} />
            <Footer />

            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"
            />
        </div>
    );
};

export default LandingLayout;
