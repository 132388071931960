import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";

import LoginWithOtp from "./LoginWithOtp";
import SendOtp from "./SendOtp";
import { sendOtp, loginWithOtp } from "store/actions";

const LoginModalContent = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");

    const { loading } = useSelector(({ loading }) => ({
        loading: loading,
    }));

    const handleSendOtp = async (value) => {
        let result = await dispatch(sendOtp(value));
        if (result) {
            toast.success("OTP sent successfully");
            setEmail(value.email);
        }
    };

    const handleOtpLogin = (value) => {
        dispatch(loginWithOtp({ email: email, otp: value.otp }));
    };

    return (
        <Grid container justify="center" style={{ paddingTop: "0.5em" }}>
            <Grid item style={{ width: "100%" }}>
                {email === "" ? (
                    <SendOtp onSubmit={handleSendOtp} loading={loading} />
                ) : (
                    <LoginWithOtp onSubmit={handleOtpLogin} loading={loading} />
                )}
            </Grid>
        </Grid>
    );
};

export default LoginModalContent;
