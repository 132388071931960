import { useState } from "react";
import { useDispatch } from "react-redux";
import { Send } from "@material-ui/icons";
import { saveBetaUsers } from "store/actions";

const Footer = () => {
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();

    const dispatch = useDispatch();

    return (
        <div className="footer-dark">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 item text">
                            <h3>Thetokenlist</h3>
                            <p>
                                Thetokenlist is an upcoming fully-fledged NFT marketplace on Solana. Get quick and easy
                                access to digital collectibles and explore, buy and sell NFTs from different collections and
                                artists.
                            </p>
                        </div>
                        <div className="col-md-6 item text">
                            <h3 className="text-md-left">Contact Us</h3>
                            <form>
                                <fieldset className="form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Enter email"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </fieldset>
                                <fieldset className="form-group">
                                    <textarea
                                        className="form-control"
                                        id="exampleMessage"
                                        placeholder="Message"
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                    ></textarea>
                                </fieldset>
                                <fieldset className="form-group text-xs-right">
                                    <button
                                        type="button"
                                        className="btn snt-main"
                                        onClick={() => dispatch(saveBetaUsers({ email, message }))}
                                    >
                                        <Send />
                                    </button>
                                </fieldset>
                            </form>
                        </div>
                        <div className="col item social">
                            <a href="#">
                                <i title="Facebook" className="icon ion-social-facebook"></i>
                            </a>
                            <a href="https://twitter.com/mytoonstore">
                                <i title="Twitter" className="icon ion-social-twitter"></i>
                            </a>
                            <a href="https://discord.gg/kmJsJqmuDD">
                                <i title="Discord" className="fab fa-discord"></i>
                            </a>
                            <a href="#">
                                <i title="Telegram" className="fab fa-telegram"></i>
                            </a>
                        </div>
                    </div>

                    <p className="copyright">Thetokenlist © 2021</p>
                </div>
            </footer>
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"
            />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css" />
            {/* <link rel="stylesheet" href="assets/css/style.css"></link> */}
        </div>
    );
};

export default Footer;
