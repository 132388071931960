import { Connection } from "@metaplex/js";
import { toast } from "react-toastify";
import { convertToSOL } from "helpers";
import { SOLANA_CLUSTER } from "helpers";
import {
    RESTORE_PHANTOM_DATA,
    UPDATE_LOADING_TEXT,
    UPDATE_PHANTOM_CONNECT,
    UPDATE_PHANTOM_INSTALL,
    UPDATE_PUBLIC_KEY,
    UPDATE_USER_BALANCE,
    RESET_LOADING_TEXT,
} from "../types";
import { toggleLoading } from "./";

export const updateConnectionStatus = (data) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_PHANTOM_CONNECT, payload: data });
    };
};

export const updateInstallationStatus = (data) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_PHANTOM_INSTALL, payload: data });
    };
};

export const updatePublicKey = (data) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_PUBLIC_KEY, payload: data });
    };
};

export const restorePhantomData = () => {
    return async (dispatch) => {
        dispatch({ type: RESTORE_PHANTOM_DATA });
    };
};

export const updateUserBalance = (publicKey) => {
    return async (dispatch) => {
        const connection = new Connection(SOLANA_CLUSTER);
        let balance = await connection.getBalance(publicKey);
        let solBalance = convertToSOL(balance);
        dispatch({ type: UPDATE_USER_BALANCE, payload: solBalance.toFixed(2) });
    };
};

export const handlePhantomConnect = () => {
    return async (dispatch) => {
        if ("solana" in window) {
            const provider = window.solana;
            if (provider.isPhantom) {
                try {
                    dispatch(toggleLoading(true));
                    const resp = await window.solana.connect();
                    dispatch(updateConnectionStatus(true));
                    dispatch(updatePublicKey(resp.publicKey.toString()));
                    dispatch(updateUserBalance(resp.publicKey));
                    toast.success("Connected to wallet");
                    dispatch(toggleLoading(false));
                } catch (err) {
                    dispatch(restorePhantomData());
                    dispatch(toggleLoading(false));
                    toast.error(err.message);
                    // { code: 4001, message: 'User rejected the request.' }
                }
            }
        } else {
            window.open("https://phantom.app/", "_blank");
        }
    };
};

export const handlePhantomConnectWithoutOpen = () => {
    return async (dispatch) => {
        if ("solana" in window) {
            const provider = window.solana;
            if (provider.isPhantom) {
                try {
                    dispatch(updateLoadingText("Please wait. We're connecting your wallet"));
                    dispatch(toggleLoading(true));
                    const resp = await window.solana.connect();
                    dispatch(updateConnectionStatus(true));
                    dispatch(updatePublicKey(resp.publicKey.toString()));
                    dispatch(updateUserBalance(resp.publicKey));
                    dispatch(toggleLoading(false));
                    dispatch(resetLoadingText());
                } catch (err) {
                    dispatch(restorePhantomData());
                    dispatch(toggleLoading(false));
                    dispatch(resetLoadingText());
                    // { code: 4001, message: 'User rejected the request.' }
                }
            } else {
                toast.info(
                    "please install Phantom extention on your browser. Phantom extension will work only on the desktop"
                );
            }
        } else {
            toast.info("please install Phantom extention on your browser. Phantom extension will work only on the desktop");
        }
    };
};

export const updateLoadingText = (data) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_LOADING_TEXT, payload: data });
    };
};

export const resetLoadingText = (data) => {
    return async (dispatch) => {
        dispatch({ type: RESET_LOADING_TEXT });
    };
};
