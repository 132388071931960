import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Backdrop, CircularProgress } from "@material-ui/core";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import { appRoutes } from "routes.js";

import componentStyles from "assets/theme/layouts/auth.js";

const useStyles = makeStyles(componentStyles);

const Auth = () => {
    const classes = useStyles();
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        document.body.classList.add(classes.bgDefault);
        return () => {
            document.body.classList.remove(classes.bgDefault);
        };
    });
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const { loading, loadingText } = useSelector(({ loading, phantom: { loadingText } }) => ({
        loading,
        loadingText,
    }));

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth") {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return <Redirect to={"/"} key={key} />;
            }
        });
    };

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <Backdrop open={loading} style={{ zIndex: 999 }}>
                    <Grid container>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <CircularProgress color="secondary" />
                        </Grid>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <h2 style={{ fontWeight: "normal", color: "white" }}>{loadingText}</h2>
                        </Grid>
                    </Grid>
                </Backdrop>
                <AuthNavbar />
                <AuthHeader />
                {/* Page content */}
                <Container
                    component={Box}
                    maxWidth="xl"
                    marginTop="-13rem"
                    paddingBottom="3rem"
                    position="relative"
                    zIndex="101"
                >
                    <Box component={Grid} container>
                        <Switch>
                            {getRoutes(appRoutes)}
                            <Redirect from="*" to="/auth/listings" />
                        </Switch>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default Auth;
