import jwtDecode from "jwt-decode";
import { unAuthorizedPost } from "services";
import { CLOSE_MODAL, LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING } from "../types";
import { LOGIN_WITH_OTP, SEND_OTP } from "graphql";

function setCurrentUser(user, token) {
    return {
        type: LOGIN_SUCCESS,
        payload: { token: token, user: user },
    };
}

export const loginSuccess = () => {
    return async (dispatch, getState) => {
        const { token } = getState().auth;
        dispatch(setCurrentUser(jwtDecode(token), token));
    };
};

export const sendOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: SEND_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // let {
            //     send_otp: { message },
            // } = apiResponse.data.data;
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const loginWithOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: LOGIN_WITH_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                login_with_otp: { token },
            } = apiResponse.data.data;

            dispatch({ type: CLOSE_MODAL });
            dispatch(setCurrentUser(jwtDecode(token), token));
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
    };
};
