export const FETCH_NFT_DETAILS = `query ($nftId: String!) {
  list_nft_details(nft_id: $nftId) {
    message
    data {
      _id
      name
      description
      owner_id {
        _id
        name
        email
        wallet_id {
          _id
          wallet_id
        }
      }
      update_authority
      art_url
      royalty_percentage
      nft_id
      maximum_supply
      is_for_sale
      selling_price
      creator_id {
        _id
        name
        email
      }
    }
  }
}
  `;

export const FETCH_ALL_SELLING_NFTS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_selling_nfts(page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      amount
      is_sold
      seller_id
      nft_id {
        _id
        name
        art_url
        nft_id
      }
    }
    hasMore
  }
}

`;

export const SELL_AN_NFT = `mutation ($nftId: String!, $amount: Float!) {
  sell_nfts(nft_id: $nftId, amount: $amount) {
    message
  }
}
`;

export const FETCH_ALL_USER_NFTS = `query ($type: String!, $pageNumber: Int, $pageSize: Int) {
  list_all_user_nfts(type: $type, page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      name
      art_url
      nft_id
    }
    hasMore
  }
}

`;

export const BUY_NFT = `mutation ($destWalletId: String!, $nftId: String!) {
  buy_nft(dest_wallet_id: $destWalletId, nft_id: $nftId) {
    message
  }
}

`;

export const BUY_METAPLEX_NFT = `mutation ($destWalletId: String!, $nftId: String!, $signature: String!) {
  buy_metaplex_nft(dest_wallet_id: $destWalletId, nft_id: $nftId, signature: $signature) {
    message
  }
}
`;

export const SAVE_NFT_DETAILS = `mutation ($name: String!, $description: String, $creatorWalletId: String!, $fileUrl: String!, $nftId: String!, $royalty: Float, $maximumSupply: Int!) {
  save_nft_data(name: $name, description: $description, creator_wallet_id: $creatorWalletId, file_url: $fileUrl, nft_id: $nftId, royalty_percentage: $royalty, maximum_supply:$maximumSupply) {
    message
    nft_id
  }
}
`;

export const PURCHASE_AN_NFT = `mutation ($nftId: String!) {
  purchase_nft(nft_id: $nftId) {
    message
  }
}
`;

export const MOVE_NFT_TO_ANOTHER_WALLET = `mutation ($destWalletId: String!, $nftId: String!) {
  move_nft_to_wallet(dest_wallet_id: $destWalletId, nft_id: $nftId) {
    message
  }
}
`;

export const LOCK_OR_UNLOCK_NFT = `mutation ($id: String!){
  lock_or_unlock_nft(_id: $id){
    message
  }
}`;

export const BUY_MULTIPLE_NFTS = `mutation ($nftId: [String]!, $destWalletId: String!, $orderCreationId: String!, $razorpayPaymentId: String!, $razorpayOrderId: String!, $razorpaySignature: String!){
  buy_multiple_nfts(
    nfts: $nftId,
    destWalletId: $destWalletId,
    orderCreationId: $orderCreationId,
    razorpayPaymentId: $razorpayPaymentId,
    razorpayOrderId: $razorpayOrderId,
    razorpaySignature: $razorpaySignature,
  ){
    message
  }
}`;