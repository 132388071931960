import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const CustomTextButton = ({
    color = "secondary",
    variant = "contained",
    size = "medium",
    onClick,
    style = {},
    title = "Click here",
    optionalProps = {},
    disabled = false,
}) => {
    const styles = disabled ? { color: "gray", borderColor: "gray" } : {};

    return (
        <Button
            color={color}
            variant={variant}
            onClick={onClick}
            style={{ ...style, ...styles }}
            size={size}
            disabled={disabled}
            // classes={disabled?classes.disableButton:null}
            {...optionalProps}
        >
            {title}
        </Button>
    );
};

export default CustomTextButton;
