import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { Card, CardContent, Grid } from "@material-ui/core";

import { listAllSellingNfts, resetSellingNfts, resetLoadingText } from "store/actions";
// import { convertToSOL } from "helpers";
import { history } from "utils";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import { lockOrUnlockNft } from "store/actions";

const floating = {
    position: "fixed",
    bottom: "40px",
    right: "80px",
    borderRadius: "20px",
    backgroundColor: "#262734",
    textAlign: "center",
    padding: "15px",
};

function Home() {
    const dispatch = useDispatch();
    const [selection, setSelection] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [data, setData] = useState([]);

    const { sellingNfts, viewMoreButton } = useSelector(({ nfts: { list, showViewMore } }) => ({
        sellingNfts: list,
        viewMoreButton: showViewMore,
    }));

    useEffect(() => {
        dispatch(resetSellingNfts());
        dispatch(resetLoadingText());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllSellingNfts());
    };

    const handleSelection = (e) => {
        let arrCopy = data;
        if (data.filter((j) => j === e?.nft_id?.nft_id).length) {
            arrCopy = data.filter((j) => j !== e?.nft_id?.nft_id);
            setTotalPrice(totalPrice - e?.amount);
        } else {
            arrCopy.push(e?.nft_id?.nft_id);
            setTotalPrice(totalPrice + e?.amount);
        }
        dispatch(lockOrUnlockNft({id: e?._id}));
        setData(arrCopy);
    };
    return (
        <>
            {sellingNfts.length ? (
                <Grid
                    container
                    justify="flex-start"
                    spacing={4}
                    style={{ padding: "1em", marginLeft: "40px", marginBottom: "100px" }}
                >
                    <div style={{ marginLeft: "15px", width: "100%" }}>
                        <Grid container>
                            <Grid item>
                                <CustomTextButton
                                    title="Buy single"
                                    size="small"
                                    disabled={selection === false}
                                    onClick={() => setSelection(false)}
                                />
                            </Grid>
                            <Grid item>
                                <CustomTextButton
                                    title="Buy multiple"
                                    size="small"
                                    disabled={selection === true}
                                    onClick={() => setSelection(true)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {sellingNfts.map((e, i) => {
                        return (
                            <Grid item xs={10} sm={5} lg={3} md={4} key={i} style={{ display: "flex" }}>
                                <Card style={{ width: "100%" }}>
                                    <CardContent>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <div
                                                    style={{
                                                        height: "40px",
                                                        display: "grid",
                                                        placeItems: "center",
                                                        textTransform: "uppercase",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <h4> {e?.nft_id?.name}</h4>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <img width="150" height="150" src={e?.nft_id?.art_url} alt="" />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" style={{ paddingTop: "5px" }}>
                                            <Grid item>
                                                <h3>{e?.amount} INR</h3>
                                            </Grid>
                                        </Grid>
                                        {selection ? (
                                            <Grid container justify="center">
                                                <Grid item>
                                                    <CustomTextButton
                                                        title={
                                                            data.filter((elemnt) => elemnt === e.nft_id.nft_id).length
                                                                ? "selected"
                                                                : "select"
                                                        }
                                                        color="primary"
                                                        onClick={() => handleSelection(e)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container justify="center">
                                                <Grid item>
                                                    <CustomTextButton
                                                        title="View Details"
                                                        color="primary"
                                                        onClick={() =>
                                                            history.push(`/auth/buy-artwork/${e?.nft_id?.nft_id}`)
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container justify="center" spacing={3} style={{ padding: "1em" }}>
                    <Grid>
                        <h2 style={{ color: "white", fontWeight: "normal" }}>No items available</h2>
                    </Grid>
                </Grid>
            )}
            {viewMoreButton ? (
                <Grid container justify="center" spacing={2} style={{ paddingTop: "10px" }}>
                    <Grid item>
                        <CustomTextButton title="Show More" onClick={() => fetchMoreData()} />
                    </Grid>
                </Grid>
            ) : null}
            {selection ? (
                <div style={floating}>
                    <b>Total price: {totalPrice} INR &nbsp;</b>
                    <CustomTextButton
                        marginTop="10px"
                        title="Buy now"
                        size="small"
                        disabled={totalPrice < 1}
                        onClick={() => history.push({ pathname: "/auth/buy-multiple-nfts", datas: { data, totalPrice } })}
                    />
                </div>
            ) : null}
        </>
    );
}

export default Home;
