import React from "react";

function Navbar() {
    return (
        <>
            {/* ***************************** Head Starts Here **********************************/}
            <div className="head-cover" style={{ backgroundColor: "rgb(26,27,40)", color: "white" }}>
                <header id="menu-jk" className="container-fluid">
                    <div className="container">
                        <div className="row head-ro">
                            <div className="col-md-3 logo">
                                <a
                                    className="d-md-none small-menu"
                                    data-bs-toggle="collapse"
                                    href="#collapseExample"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    <i className="fas d-lg-none  fa-bars" />
                                </a>
                                <a href="/">
                                    <h3
                                        style={{
                                            color: "#3dc3ff",
                                            padding: "10px",
                                            fontFamily: "'Maven Pro', sans-serif",
                                        }}
                                    >
                                        Tokenlist
                                    </h3>
                                </a>
                            </div>
                            <div id="collapseExample" className="col-md-9  nav">
                                <ul>
                                    <li>
                                        <a href="/">home</a>
                                    </li>
                                    <li>
                                        <a href="/auth/listings">listings</a>
                                    </li>
                                    {/* <li>
                    <a href="/auth/creators">creators</a>
                  </li> */}
                                    <li className="btnll">
                                        <a href="https://wallet.thetokenlist.com/auth/signin">
                                            <button
                                                className="btn btn-sm btn-primary"
                                                style={{ backgroundColor: "#3dc3ff", color: "black", border: 0 }}
                                            >
                                                Login
                                            </button>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
}

export default Navbar;
