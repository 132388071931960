import React from "react";
import "./useCases.css";
import img1 from "assets/img/usecases/use1.png";
import img2 from "assets/img/usecases/use2.png";
import img3 from "assets/img/usecases/use3.png";
import img4 from "assets/img/usecases/use4.png";
import img5 from "assets/img/usecases/use5.png";
import img6 from "assets/img/usecases/use6.png";
import img7 from "assets/img/usecases/use7.png";
import img8 from "assets/img/usecases/use8.png";

function UseCases() {
    const uses = [
        { title: "entertainment", img: img1 },
        { title: "travel", img: img2 },
        { title: "hospitality", img: img3 },
        { title: "real estate", img: img4 },
        { title: "coupons", img: img5 },
        { title: "membership", img: img6 },
        { title: "agriculture", img: img7 },
        { title: "equity", img: img8 },
    ];

    return (
        <>
            <section id="usecases" className="usecases container-fluid">
                <div className="container">
                    <div>
                        <h3>
                            <span> various areas where The Tokenlist </span> platform can be used
                        </h3>
                    </div>

                    <div className="row mt-5 usecase-row">
                        {uses.map((e, i) => (
                            <div className="col-md-3" key={i}>
                                <div className="usecase-col">
                                    <h4>{e.title}</h4>
                                    <img src={e.img} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>

                    <p>
                        real-world assets can be now tokenised through a smart
                        contract
                    </p>
                </div>
            </section>
        </>
    );
}

export default UseCases;
