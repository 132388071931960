import { LIST_ALL_CREATORS, LIST_ALL_CREATOR_NFTS, RESET_ALL_CREATORS, RESET_ALL_CREATORS_NFTS } from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 15,
    details: {},
    arts: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 15,
    },
};

const creatorsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_CREATORS:
            return {
                ...state,
                pageSize: INITIAL_STATE.pageSize,
                ...payload,
            };

        case LIST_ALL_CREATOR_NFTS:
            return {
                ...state,
                details: payload.details,
                arts: {
                    pageSize: INITIAL_STATE.arts.pageSize,
                    ...payload.arts,
                },
            };

        case RESET_ALL_CREATORS:
            return {
                ...state,
                list: INITIAL_STATE.list,
                pageNumber: INITIAL_STATE.pageNumber,
                pageSize: INITIAL_STATE.pageSize,
                showViewMore: INITIAL_STATE.showViewMore,
                details: INITIAL_STATE.details,
            };

        case RESET_ALL_CREATORS_NFTS:
            return {
                ...state,
                arts: INITIAL_STATE.arts,
            };
        default:
            return state;
    }
};

export default creatorsReducer;
