import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import { closeModal } from "store/actions";

const getModalWidth = () => {
    let currentWidth = window.innerWidth;
    if (currentWidth > 1500) {
        return parseFloat(((30 / 100) * currentWidth).toFixed(2));
    } else if (currentWidth > 1000 && currentWidth <= 1500) {
        return parseFloat(((50 / 100) * currentWidth).toFixed(2));
    } else if (currentWidth >= 500 && currentWidth <= 600) {
        return parseFloat(((70 / 100) * currentWidth).toFixed(2));
    } else if (currentWidth > 600 && currentWidth <= 1000) {
        return parseFloat(((60 / 100) * currentWidth).toFixed(2));
    } else {
        return parseFloat(((100 / 100) * currentWidth).toFixed(2));
    }
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: "#060708",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: getModalWidth(),
        borderColor: "#f5f5f9",
    },
}));

const CustomModal = ({ title = "" }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { open, content } = useSelector(({ modal: { open, content } }) => ({
        open: open,
        content: content,
    }));

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => dispatch(closeModal())}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h4
                            style={{
                                textTransform: "uppercase",
                                fontSize: "15px",
                                fontWeight: "bold",
                                fontFamily: "inherit",
                                // color:"wh"
                            }}
                        >
                            {title}
                        </h4>
                        {content[0]}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default CustomModal;
