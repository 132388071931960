import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// import "bootstrap/dist/css/bootstrap.css";
import { SET_STATE_UNDIFINED } from "store/types";
import Nav from "views/old-landing/navbar";
import UpcomingNFTs from "views/old-landing/upcoming_nfts";
import Footer from "views/old-landing/footer";

function EduPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_STATE_UNDIFINED });
    }, []);

    return (
        <div className="style">
            {/*  navbar */}

            <Nav />
            {/* Hero unit */}
            <div className="hero">
                <div className="hero__overlay hero__overlay--gradient" />
                <div className="hero__mask" />
                <div className="hero__inner">
                    <div className="container">
                        <div className="hero__content">
                            <div className="hero__content__inner" id="navConverter">
                                <h1 className="hero__title">Signup for web3 school now</h1>
                                <p className="hero__title">
                                    When you sign up for Web3 School, we want to make sure you have a bright future.
                                    Complete weekly exercises to become a web3 pro. Meet with other aspiring students
                                    through weekly meetups or just bounce ideas off each other. You may can even meet the
                                    founders of other startups.
                                </p>

                                {/* <a
                  href="/auth/listings"
                  className="button hero__button"
                  style={{ textDecoration: "none" }}
                >
                  View Demo
                </a> */}
                                <a
                                    href="/auth/contactus"
                                    className="button button__accent"
                                    style={{ textDecoration: "none" }}
                                >
                                    Signup
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="hero__sub">
        <span id="scrollToNext" className="scroll">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            className="hero__sub__down"
            fill="currentColor"
            width="512px"
            height="512px"
            viewBox="0 0 512 512"
            style={{ enableBackground: "new 0 0 512 512" }}
            xmlSpace="preserve"
          >
            <path d="M256,298.3L256,298.3L256,298.3l174.2-167.2c4.3-4.2,11.4-4.1,15.8,0.2l30.6,29.9c4.4,4.3,4.5,11.3,0.2,15.5L264.1,380.9c-2.2,2.2-5.2,3.2-8.1,3c-3,0.1-5.9-0.9-8.1-3L35.2,176.7c-4.3-4.2-4.2-11.2,0.2-15.5L66,131.3c4.4-4.3,11.5-4.4,15.8-0.2L256,298.3z" />
          </svg>
        </span>
      </div> */}
            {/* Steps */}
            {/* <div className="steps landing__section">
        <div className="container">
          <h2>Key Benefits</h2>
          <p>Key benefits for the community members.</p>
        </div>
        <div className="container">
          <div className="steps__inner">
            <div className="step">
              <div className="step__media">
                <img
                  src="https://i.ibb.co/r7ryJDz/image.png"
                  className="step__image"
                  alt=""
                />
              </div>
              <h4>Refund Guarantee</h4>
              <p className="step__text">
                If you want to get a refund for the NFT you have purchased from
                our creators within a time frame (30 days as we launch). You get
                100% refund from our creators but we deduct a 5% market place
                fee.
              </p>
            </div>
            &nbsp;&nbsp;
            <div className="step">
              <div className="step__media">
                <img
                  src="https://i.ibb.co/SxsF0V2/image.png"
                  className="step__image"
                  alt=""
                />
              </div>
              <h4>NFT Stake Pool</h4>
              <p className="step__text">
                We will be soon launching a stakepool for all our members who
                wish to hold their NFT for long term. Members who are part of
                the stakepool will be rewarded.
              </p>
            </div>
            &nbsp;&nbsp;
            <div className="step">
              <div className="step__media">
                <img
                  src="https://i.ibb.co/V9VcwvF/image.png"
                  className="step__image"
                  alt=""
                />
              </div>
              <h4>Easy to share</h4>
              <p className="step__text">
                You can now share/reward your NFT to any of your friend who
                doesnt even have a web3 wallet by just entering their email ID.
              </p>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="step">
                <div className="step__media">
                  <img src="https://i.ibb.co/yfvPx9f/image.png" 
                  className="step__image" 
                  alt=""
                  />
                </div>
                <h4>Royalty not just for creators</h4>
                <p className="step__text">
                  We provide members an option to earn royalty from a resale.</p>
              </div>
          </div>
          <br/>
        </div>
      </div> */}

            <div className="steps landing__section ">
                <div className="container">
                    <UpcomingNFTs />
                </div>
            </div>

            {/* Expanded sections */}
            {/* <div className="expanded landing__section">
          <div className="container">
            <div className="expanded__inner">
              <div className="expanded__media">
                <img src="./images/undraw_browser.svg" className="expanded__image" />
              </div>
              <div className="expanded__content">
                <h2 className="expanded__title">Natural styling with almost nothing to learn</h2>
                <p className="expanded__text">Evie brings you the pages you'll need and the structure to create your own, without a learning curve. It is minimal and mostly styles plain elements. There are only a few classes you'll need to know but nothing to disrupt your preferred coding style.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="expanded landing__section">
          <div className="container">
            <div className="expanded__inner">
              <div className="expanded__media">
                <img src="./images/undraw_frameworks.svg" className="expanded__image" />
              </div>
              <div className="expanded__content">
                <h2 className="expanded__title">Framework agnostic. Your front-end setup, your choice.</h2>
                <p className="expanded__text">Evie has zero dependencies and uses vanilla JavaScript for a few functions with minimal footprint. You can use React, Vue, Angular, jQuery or whatever you prefer.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="expanded landing__section">
          <div className="container">
            <div className="expanded__inner">
              <div className="expanded__media">
                <img src="./images/together.svg" className="expanded__image" />
              </div>
              <div className="expanded__content">
                <h2 className="expanded__title">Ready for production or rapid prototyping</h2>
                <p className="expanded__text">Landing, authentication and a few other pages to select from, despite the small size. Tested on production at unDraw with amazing speeds and unopinionated on how to structure your project. We really hope you'll find it awesome and useful!</p>
              </div>
            </div>
          </div>
        </div> */}
            {/* Call To Action */}
            <div className="cta cta--reverse">
                <div className="container">
                    <div className="cta__inner">
                        <h2 className="cta__title">To join developer community click here</h2>
                        {/* <p className="cta__sub cta__sub--center">
              Join our discord community... <br />
              Initial 5000 members will be rewarded.
            </p> */}
                        <br />
                        <a
                            href="https://telegram.me/+pcbHoQSQMctlNGM8"
                            className="button button__accent"
                            style={{ textDecoration: "none" }}
                        >
                            Join Now
                        </a>
                    </div>
                </div>
            </div>
            {/* Footer */}

            <Footer />
        </div>
    );
}

export default EduPage;
