import React, { useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import theme from "assets/theme/theme.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "react-toastify/dist/ReactToastify.css";

import AppLayout from "layouts/Auth.js";

import { history } from "utils";
import LandingLayout from "views/landing-page";
import TermsAndConditions from "views/company-details/terms";
import RefundPolicy from "views/company-details/refunds";
import PrivacyPolicy from "views/company-details/privacy";
import ContactUs from "views/company-details/contact";
import ExploreStartups from "views/landing-page/explore-startups";
import EduPage from "views/edu-page";
import CreateWebApp from "views/create-new-webapp";
import ScheduleDemoPage from "views/schesule-a-demo";

const Main = () => {
    const [index, setIndex] = useState(null);

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Router history={history}>
                    <Switch>
                        <Route path="/auth" render={(props) => <AppLayout {...props} />} />
                        <Route path="/explore" render={(props) => <ExploreStartups index={index} {...props} />} />
                        <Route path="/schedule" render={(props) => <ScheduleDemoPage {...props} />} />
                        <Route path="/edu" render={(props) => <EduPage {...props} />} />
                        <Route path="/terms-and-conditions" render={(props) => <TermsAndConditions {...props} />} />
                        <Route path="/refund-policy" render={(props) => <RefundPolicy {...props} />} />
                        <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
                        <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
                        <Route path="/create" render={(props) => <CreateWebApp {...props} />} />
                        <Route path="/" render={(props) => <LandingLayout setIndex={setIndex} {...props} />} />
                        <Redirect from="/" to="/" />
                    </Switch>
                </Router>
            </ThemeProvider>
        </>
    );
};

export default Main;
