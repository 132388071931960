import {
    UPDATE_PHANTOM_CONNECT,
    UPDATE_PHANTOM_INSTALL,
    UPDATE_PUBLIC_KEY,
    RESTORE_PHANTOM_DATA,
    UPDATE_USER_BALANCE,
    UPDATE_LOADING_TEXT,
    RESET_LOADING_TEXT,
} from "../types";

const INITIAL_STATE = {
    isInstalled: false,
    isConnected: false,
    connectedPubKey: "",
    balance: 0,
    loadingText: "Loading... Please Wait",
};

const phantomReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case UPDATE_PHANTOM_CONNECT:
            return { ...state, isConnected: payload };
        case UPDATE_PHANTOM_INSTALL:
            return { ...state, isInstalled: payload };
        case UPDATE_PUBLIC_KEY:
            return { ...state, connectedPubKey: payload };
        case UPDATE_USER_BALANCE:
            return { ...state, balance: payload };
        case UPDATE_LOADING_TEXT:
            return { ...state, loadingText: payload };
        case RESET_LOADING_TEXT:
            return { ...state, loadingText: INITIAL_STATE.loadingText };
        case RESTORE_PHANTOM_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default phantomReducer;
