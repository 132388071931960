import React from "react";

const Nav = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top appNavbar">
            <div className="container-fluid">
                <a className="navbar-brand">
                    <strong>Thetokenlist</strong>
                </a>
                {/* <button
          className="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarExample01"
          aria-controls="navbarExample01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button> */}
                <div className="collapse navbar-collapse" id="navbarExample01">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item active">
                            <a className="nav-link" rel="nofollow" href="/auth/listings">
                                Listings
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" rel="nofollow" href="/auth/creators">
                                Creators
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
