import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FilledInput, Backdrop, CircularProgress } from "@material-ui/core";
import CustomTextButton from "components/CustomButton/CustomTextButton";

const SendOtp = ({ onSubmit, loading }) => {
    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Formik
                initialValues={{
                    email: "",
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email("Enter valid email address").max(255).required("Email is required"),
                })}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" style={{ textTransform: "uppercase", color: "white", fontSize: 15 }}>
                                Email address
                            </label>
                            <br />
                            <FilledInput
                                key="email"
                                name="email"
                                // autoComplete="off"
                                type="text"
                                onBlur={handleBlur}
                                placeholder="Email"
                                onChange={handleChange}
                                value={values.email}
                                error={Boolean(touched.email && errors.email)}
                            />

                            {Boolean(touched.email && errors.email) ? (
                                <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                    {errors.email}
                                </div>
                            ) : (
                                ""
                            )}
                            <br />
                        </div>
                        <div>
                            <CustomTextButton
                                title="Send OTP"
                                style={{ width: "100%" }}
                                onClick={handleSubmit}
                                disabled={values.email === ""}
                                type="submit"
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default SendOtp;
