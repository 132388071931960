import React from "react";
import "./currentUsecases.css";
import img1 from "assets/img/currentUsecases/img1.png";
import img2 from "assets/img/currentUsecases/img2.png";
import img3 from "assets/img/currentUsecases/img3.png";
import img4 from "assets/img/currentUsecases/img4.png";

function CurrentUsecases() {
    const uses = [
        { title: "NFT marketplace, Create NFTs, Sell NFTs both in crypto & Fiat", img: img1 },
        { title: "Email & Chat based web3 wallet management ", img: img2 },
        { title: "NFT and crypto staking services", img: img3 },
        { title: "Creation of new tokens", img: img4 },
    ];

    return (
        <>
            <section id="current-usecases" className="current-usecases container-fluid">
                <div className="container">
                    <div>
                        <h3>
                            <span>current use case of The Tokenlist </span> low code
                            web 3 platform{" "}
                        </h3>
                    </div>

                    <div className="row mt-5 usecase-row">
                        {uses.map((e, i) => (
                            <div className="col-md-6" key={i}>
                                <div className="usecase-col">
                                    <h4>{e.title}</h4>
                                    <img src={e.img} alt="" style={{width: i === 1 ? "300px" : null}}/>
                                </div>
                            </div>
                        ))}
                    </div>

                    <p>
                    enable startups with the above use cases to <b>launch an MVP in under 24 hrs</b>
                    </p>
                </div>
            </section>
        </>
    );
}

export default CurrentUsecases;
