import { OPEN_MODAL, CLOSE_MODAL } from "../types";

const INITIAL_STATE = {
    open: false,
    content: [],
};

const modalReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case OPEN_MODAL:
            return {
                open: true,
                content: [payload],
            };

        case CLOSE_MODAL:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default modalReducer;
